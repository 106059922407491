<template>
  <AsyncImage
    v-bind="$attrs"
    :src="
      getImageUrl(
        emote.id,
        'emote',
        emote.attributes.kind,
        emote.attributes.fileName,
      )
    "
    class="object-contain rounded-md"
    :class="{
      'h-12 w-12': !size || size === 'normal',
      'h-8 w-8': size === 'small',
      'saturate-0': isLocked,
    }"
  ></AsyncImage>
</template>

<script setup lang="ts">
import type {
  EmoteEntity,
  EmoteRelation,
  PopulateRelationship,
} from "~/src/api";

defineComponent({
  inheritAttrs: false,
});

defineProps<{
  emote: EmoteEntity | PopulateRelationship<EmoteRelation>;
  quality?: "128" | "256" | "512" | "original";
  size?: "normal" | "small" | "large";
  isLocked?: boolean;
}>();

const { getImageUrl } = useMediaLink();
</script>
